body {
  background-color: #183550;
  font-family: "Inter";
}

.background {
  color: #183550;
  background-color: #183550;
  padding: 20px;
  margin: auto;
  justify-content: center;
  align-items: center;
  max-width: 1116px;
  border-radius: 10px;
}

.timeline-container {
  height: 250px;
  overflow: auto;
  background: white;
  border-radius: 10px;
  padding: 20px;
}

.timeline-item {
  position: relative;
  display: flex;
  justify-content: start;
  transition: background-color 0.3s linear;
}

.timeline-item:hover {
  background-color: #f0f0f0;
  border-radius: 4px;
}

.timeline-date {
  position: relative;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  min-width: 100px;
  text-align: left;
  color: #183550;
  margin-top: 0px;
}

.timeline-content {
  padding: 16px;
  padding-top: 0px;
  border-left: 3px solid #999999;
  transition: border-color 0.3s linear;
}

.timeline-content:hover {
  border-color: #183550;
}

.timeline-content-title {
  font-size: 16px;
  color: #183550;
  font-weight: 500;
}

.timeline-content-subtitle {
  font-size: 14px;
  color: #555555;
  margin-top: 4px;
  font-weight: 500;
}

.timeline-content-description {
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 8px;
  color: #555555;
}

.grid {
  display: grid;
  gap: 20px;
  grid-auto-rows: min-content;
  margin-bottom: 20px;
  grid-auto-rows: fit-content(0em);
}

@media (min-width: 800px) {
  .grid {
    grid-template-columns: 0.85fr 1fr;
  }
}

.summary {
  height: 275px;
  height: min-content;
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  display: flex;
  min-width: min-content;
  place-items: center;
}

.summary-title {
  font-size: 32px;
  color: #183550;
  font-weight: 500;
}

.summary-subtitle {
  font-size: 14px;
  color: #183550;
}

.summary-description {
  font-size: 14px;
  color: #183550;
  font-weight: normal;
  margin-top: 16px;
}

.button {
  background-color: #183550;
  color: white;
  padding-left: 8px;
  padding-right: 8px;
  justify-content: center;
  text-align: center;
  align-content: ce;
  border-radius: 4px;
  margin-top: 16px;
  height: 32px;
  border: none;
  margin-right: 16px;
  display: inline-block;
  font-size: 14px;
  vertical-align: middle;
  cursor: pointer;
}

.border-button {
  background-color: transparent;
  border-width: 1px;
  border-color: #183550;
  color: #183550;
  padding-left: 12px;
  min-width: max-content;
  padding-right: 12px;
  height: 32px;
  border-radius: 4px;
  margin-top: 12px;
  display: inline-block;
  font-size: 14px;
  vertical-align: middle;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(0, 0, 0, 0);
  border: 10px solid transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 10px solid transparent;
  background-color: rgba(33, 37, 41, 0.3);
}

.right-container {
  display: flex;
  min-width: min-content;
  align-items: center;
}

@media (min-width: 800px) {
  .right-container {
    min-height: min-content;
    height: auto;
  }
}

.dribble-shots {
  max-height: 250px;
  min-height: 150px;
  background: white;
  border-radius: 10px;
  padding: 20px;
}

.artstation-image-container {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.artstation-image {
  overflow: hidden;
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
}

.artstation-image-container img {
  transition: 0.40s all ease-in-out;
}

.artstation-image-container:hover img {
  transform: scale(1.1);
}

.row {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: start;
}

.row-child {
  padding-left: 8px;
  font-size: 14px;
  margin-top: 16px;
}

.artstation-button-desc {
  padding-left: 8px;
  font-size: 14px;
  text-align: start;
  margin-top: 16px;
}

.coming-soon {
  display: flex;
  height: 250px;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 10px;
  padding: 20px;
}

@media(max-width: 800px) {
  .artstation-button-desc {
    font-size: 12px;
  }
}

.bottom-bar {
  height: 52px;
  margin-top: 20px;
  padding: auto;
  background-color: white;
  border-radius: 10px;
  text-align: center;
}

.bottom-bar-item {
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.profile-image-border {
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-height: min-content;
  justify-content: center;
  object-fit: contain;
}

@media (max-width: 800px) {
  .profile-image-border {
    max-height: fit-content;
  }
}

@media (-webkit-min-device-pixel-ratio: 0) and (max-width: 800px) {
  .profile-image-border {
    width: 100%;
    height: 100%;
    min-height: 100%;
  }
}

@media (-webkit-min-device-pixel-ratio: 0) and (min-width: 800px) {
  .profile-image-border {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
